export const GET_BSU = "GET_BSU";
export const GET_BSU_SUCCESSFUL = "GET_BSU_SUCCESSFUL";

export const ADD_NEW_BSU = "ADD_NEW_BSU";
export const UPDATE_BSU = "UPDATE_BSU";
export const DELETE_BSU = "DELETE_BSU";

export const BSU_API_OK = "BSU_API_OK";
export const BSU_API_ERROR = "BSU_API_ERROR";

export const RESET_BSU_FLAG = "RESET_BSU_FLAG";