/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
    Button,
    Col,
    Container,
    Row,
    Form,
    Card,
    CardBody,
    CardHeader,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Spinner,
    Input,
    FormFeedback,
    Table,
} from "reactstrap";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";

import { getDataTransactionDetails, getDataTransaction as onLoadData, resetTransactionFlag as resetFlag } from "../../store/actions";

import withRouter from "../../Components/Common/withRouter";
import { withTranslation } from "react-i18next";

const Transaction = (props) => {
    const dispatch = useDispatch();

    const successnotify = (message) =>
        toast(message, {
            position: "top-center",
            hideProgressBar: true,
            closeOnClick: false,
            className: "bg-success text-white",
        });

    const errornotify = (message) =>
        toast(message, {
            position: "top-center",
            hideProgressBar: true,
            closeOnClick: false,
            className: "bg-danger text-white",
        });

    const [page, setPage] = useState(1);
    const [per_page] = useState(50);
    const [hasNextPage, setHasNextPage] = useState(false);
    const [offset, setOffset] = useState(1);
    const [data, setData] = useState(null);
    const [item, setItem] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loadingList, setLoadingList] = useState(false);

    const [modal_list, setmodal_list] = useState(false);
    const tog_list = () => {
        setmodal_list(!modal_list);
    };

    const { transactionList, details, baseLoading, baseLoadingList, success, error, message } = useSelector((state) => ({
        transactionList: state.Transaction.transactionList,
        details: state.Transaction.details,
        baseLoading: state.Transaction.loading,
        baseLoadingList: state.Transaction.loadingList,
        success: state.Transaction.success,
        error: state.Transaction.error,
        message: state.Transaction.message,
    }))

    useEffect(() => {
        if (transactionList) {
            setHasNextPage(transactionList.has_next_page);
        }

        if (transactionList && transactionList.items?.length > 0) {
            setData(transactionList);
        } else {
            setData(null);
        }
    }, [transactionList]);

    useEffect(() => {
        if (details) {
            setItem(details);
        } else {
            setItem(null);
        }
    }, [details]);

    useEffect(() => {
        if (success) {
            successnotify(message);
            dispatch(onLoadData({ 'page': page, 'per_page': per_page }));
            setmodal_list(false);
            setItem(null);
            setTimeout(() => {
                dispatch(resetFlag("success", false));
                dispatch(resetFlag("message", null));
            }, 500);
        }
    }, [success, message]);

    useEffect(() => {
        if (error) {
            errornotify(message);
            setTimeout(() => {
                dispatch(resetFlag("error", false));
                dispatch(resetFlag("message", null));
            }, 500);
        }
    }, [error, message]);

    useEffect(() => {
        setLoading(baseLoading);
        setLoadingList(baseLoadingList);
    }, [baseLoading, baseLoadingList])

    useEffect(() => {
        dispatch(onLoadData({ 'page': page, 'per_page': per_page }));
    }, []);

    const filterData = (event) => {
        const keyword = event.target.value;
        setPage(1);
        setOffset(1);
        if (keyword.length > 0) {
            dispatch(onLoadData({ 'page': page, 'per_page': per_page, 'search': keyword }));
        } else {
            dispatch(onLoadData({ 'page': page, 'per_page': per_page }));
        }
    }

    const formatRupiah = (number) => {
        const numberString = number.toString().replace(/[^,\d]/g, '');
        const parts = numberString.split(',');
        let result = '';
        let count = 0;

        // Format bagian ribuan
        for (let i = parts[0].length - 1; i >= 0; i--) {
            count++;
            result = parts[0].charAt(i) + result;
            if (count % 3 === 0 && i !== 0) {
                result = '.' + result;
            }
        }

        // Format bagian desimal
        if (parts[1]) {
            result += ',' + parts[1];
        }

        return `Rp${result}`;
    };

    const formatDate = (val) => {
        const date = new Date(val);

        const day = date.getDate();
        const month = date.toLocaleDateString('id-ID', { month: 'long' });
        const year = date.getFullYear();

        return `${day} ${month} ${year}`;
    }

    document.title = "Transaksi - Bank Sampah Sehati";
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xl={12}>
                            <Card>
                                <CardHeader className="align-items-center d-flex">
                                    <h4 className="card-title mb-0 flex-grow-1">
                                        Data Transaksi
                                    </h4>
                                    <Col className="col-sm">
                                        <div className="d-flex justify-content-sm-end">
                                            <div className="search-box ms-2">
                                                <input
                                                    type="text"
                                                    className="form-control search"
                                                    placeholder="Cari..."
                                                    onChange={filterData}
                                                />
                                                <i className="ri-search-line search-icon"></i>
                                            </div>
                                        </div>
                                    </Col>
                                </CardHeader>

                                <CardBody>
                                    <div className="table-responsive table-card">
                                        <table className="table table-hover table-centered align-middle mb-0">
                                            <thead className="text-muted table-light">
                                                <tr>
                                                    <th scope="col">Tanggal</th>
                                                    <th scope="col">Pengguna</th>
                                                    <th scope="col">Total</th>
                                                    <th scope="col">Status</th>
                                                    <th scope="col">Aksi</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {loadingList ? <tr>
                                                    <td colSpan={5}>
                                                        <div className="d-flex align-items-center justify-content-center">
                                                            <Spinner size="sm" color="primary" />
                                                        </div>
                                                    </td>
                                                </tr> : data && data.items.length > 0 ? data.items.map((item, index) => {
                                                    const date = new Date(item.created_on);

                                                    const day = date.getDate();
                                                    const month = date.toLocaleDateString('id-ID', { month: 'long' });
                                                    const year = date.getFullYear();

                                                    const created_on = `${day} ${month} ${year}`;

                                                    let user = '';
                                                    if (item.userId === item.bsu.user.id) {
                                                        user = `${item.bsu.trash_bank_name} (BSU)`
                                                    } else if (item.userId === item.collector.user.id) {
                                                        user = `${item.collector.trash_bank_name} (BSI)`
                                                    } else if (item.userId === item.customer.user.id) {
                                                        user = `${item.customer.user.first_name} ${item.customer.user.last_name || ''}`
                                                    }

                                                    return (
                                                        <tr key={index}>
                                                            <td>{created_on}</td>
                                                            <td>{user}</td>
                                                            <td>{formatRupiah(item.amount)}</td>
                                                            <td>{item.status === "settlement" ? <span className="badge badge-soft-success">{props.t(item.status)}</span> : <span className="badge badge-soft-primary">{props.t(item.status)}</span>}</td>
                                                            <td>
                                                                <div className="d-flex gap-2">
                                                                    <div className="edit">
                                                                        <button className="btn btn-sm btn-light edit-item-btn"
                                                                            data-bs-toggle="modal" onClick={() => {
                                                                                tog_list();
                                                                                dispatch(getDataTransactionDetails({ 'id': item.id }));
                                                                            }}>Detail</button>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                }) : <tr>
                                                    <td colSpan={5}>
                                                        <div className="d-flex align-item-center justify-content-center">
                                                            Tidak ada data
                                                        </div>
                                                    </td>
                                                </tr>}
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="align-items-center mt-4 pt-2 justify-content-between row text-center text-sm-start">
                                        <div className="col-sm">
                                            <div className="text-muted">
                                                <span className="fw-semibold">{loadingList ? '0' : transactionList.total_records === 0 ? '0' : per_page > transactionList.total_records ? `${offset}-${transactionList.total_records}` : hasNextPage ? `${offset}-${page * per_page}` : `${offset}-${transactionList.total_records}`}</span> dari <span className="fw-semibold">{loadingList ? '0' : transactionList.total_records}</span>
                                            </div>
                                        </div>
                                        <div className="col-sm-auto mt-3 mt-sm-0">
                                            <ul className="pagination pagination-separated pagination-sm mb-0 justify-content-center">
                                                <li className={page > 1 ? 'page-item' : 'page-item disabled'}>
                                                    <Button onClick={() => {
                                                        dispatch(onLoadData({ 'page': page - 1, 'per_page': per_page }));
                                                        setPage(page - 1);
                                                        setOffset(offset - per_page);
                                                    }} className="page-link">←</Button>
                                                </li>
                                                <li className={hasNextPage ? 'page-item' : 'page-item disabled'}>
                                                    <Button onClick={() => {
                                                        dispatch(onLoadData({ 'page': page + 1, 'per_page': per_page }));
                                                        setPage(page + 1);
                                                        setOffset(offset + per_page);
                                                    }} className="page-link">→</Button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <ToastContainer />
                </Container>
            </div>

            {/* Details Modal */}
            <Modal size="xl" isOpen={modal_list} toggle={() => {
                tog_list();
                setItem(null);
            }} centered >
                <ModalHeader className="bg-light p-3" toggle={() => {
                    tog_list();
                    setItem(null);
                }}> {'Detail'} </ModalHeader>
                <ModalBody>
                    <Row className="justify-content-center">
                        {loading ? <Spinner color="primary" /> :
                            <Row>
                                <Col lg={12}>
                                    <CardBody className="p-4">
                                        <Row className="g-3">
                                            <Col lg={4} xs={6}>
                                                <p className="text-muted mb-2 text-uppercase fw-semibold">
                                                    Tanggal
                                                </p>
                                                <h5 className="fs-14 mb-0">
                                                    <span id="invoice-date">{formatDate(item?.created_on)}</span>
                                                </h5>
                                            </Col>
                                            <Col lg={4} xs={6}>
                                                <p className="text-muted mb-2 text-uppercase fw-semibold">
                                                    Status Transaksi
                                                </p>
                                                {item?.status === "settlement" ? <span className="badge badge-soft-success fs-11" id="payment-status">{props.t(item?.status)}</span> : <span className="badge badge-soft-primary fs-11" id="payment-status">{props.t(item?.status)}</span>}
                                            </Col>
                                            <Col lg={4} xs={6}>
                                                <p className="text-muted mb-2 text-uppercase fw-semibold">
                                                    Total
                                                </p>
                                                <h5 className="fs-14 mb-0"><span id="total-amount">{item ? formatRupiah(item?.amount) : ""}</span></h5>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Col>
                                <Col lg={12}>
                                    <CardBody className="p-4 border-top border-top-dashed">
                                        <Row className="g-3">
                                            {item?.collector && <Col sm={6}>
                                                <h6 className="text-muted text-uppercase fw-semibold mb-3">
                                                    Bank Sampah Induk
                                                </h6>
                                                <p className="fw-medium mb-2" id="billing-name">{item?.collector.trash_bank_name}</p>
                                                <p className="text-muted mb-1" id="billing-address-line-1">{item?.collector.address}</p>
                                                <p className="text-muted mb-1"><span>Nomor Ponsel: </span><span id="billing-phone-no">{item?.collector.user.phone_number}</span></p>
                                            </Col>}
                                            <Col sm={6}>
                                                <h6 className="text-muted text-uppercase fw-semibold mb-3">
                                                    Bank Sampah Unit
                                                </h6>
                                                <p className="fw-medium mb-2" id="billing-name">{item?.bsu.trash_bank_name}</p>
                                                <p className="text-muted mb-1" id="billing-address-line-1">{item?.bsu.address}</p>
                                                <p className="text-muted mb-1"><span>Nomor Ponsel: </span><span id="billing-phone-no">{item?.bsu.user.phone_number}</span></p>
                                            </Col>
                                            {item?.customer &&
                                                <Col sm={6}>
                                                    <h6 className="text-muted text-uppercase fw-semibold mb-3">
                                                        Nasabah
                                                    </h6>
                                                    <p className="fw-medium mb-2" id="shipping-name">{`${item?.customer?.user.first_name} ${item?.customer?.user.last_name || ''}`}</p>
                                                    <p className="text-muted mb-1" id="shipping-address-line-1">{item?.customer?.address}</p>
                                                    <p className="text-muted mb-1"><span>Nomor Ponsel: </span><span id="shipping-phone-no">{item?.customer?.user.phone_number}</span></p>
                                                </Col>}
                                        </Row>
                                    </CardBody>
                                </Col>
                                <Col lg={12}>
                                    <CardBody className="p-4">
                                        <div className="table-responsive">
                                            <Table className="table-borderless text-center table-nowrap align-middle mb-0">
                                                <thead>
                                                    <tr className="table-active">
                                                        <th scope="col" style={{ width: "50px" }}>
                                                            #
                                                        </th>
                                                        <th scope="col">Nama Sampah</th>
                                                        <th scope="col">Harga</th>
                                                        <th scope="col">QTY</th>
                                                        <th scope="col" className="text-end">
                                                            Total
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody id="products-list">
                                                    {item && item.details?.length > 0 && item.details.map((item, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <th scope="row">
                                                                    {index + 1}
                                                                </th>
                                                                <td className="text-start">
                                                                    <span className="fw-medium">
                                                                        {item.trash.trash_name}
                                                                    </span>
                                                                </td>
                                                                <td>{formatRupiah(item.trash.selling_price)}</td>
                                                                <td>{`${item.qty} ${item.unit}`}</td>
                                                                <td className="text-end">{formatRupiah(item.qty * item.amount)}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </Table>
                                        </div>
                                        <div className="border-top border-top-dashed mt-2">
                                            <Table className="table table-borderless table-nowrap align-middle mb-0 ms-auto" style={{ width: "250px" }}>
                                                <tbody>
                                                    <tr className="fs-15">
                                                        <th scope="row">Total</th>
                                                        <th className="text-end">{item ? formatRupiah(item?.amount) : ""}</th>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                </Col>
                            </Row>
                        }
                    </Row >
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

Transaction.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
};

export default withRouter(withTranslation()(Transaction));
