/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
    Button,
    Col,
    Container,
    Row,
    Form,
    Card,
    CardBody,
    CardHeader,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Spinner,
    Input,
    FormFeedback,
} from "reactstrap";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";

import { getDataUser as onLoadData, addUser as onCreate, updateUser as onUpdate, deleteUser as onDelete, resetUserFlag as resetFlag } from "../../store/actions";

import withRouter from "../../Components/Common/withRouter";
import { withTranslation } from "react-i18next";

const User = (props) => {
    const dispatch = useDispatch();

    const successnotify = (message) =>
        toast(message, {
            position: "top-center",
            hideProgressBar: true,
            closeOnClick: false,
            className: "bg-success text-white",
        });

    const errornotify = (message) =>
        toast(message, {
            position: "top-center",
            hideProgressBar: true,
            closeOnClick: false,
            className: "bg-danger text-white",
        });

    const [page, setPage] = useState(1);
    const [per_page] = useState(50);
    const [hasNextPage, setHasNextPage] = useState(false);
    const [offset, setOffset] = useState(1);
    const [data, setData] = useState(null);
    const [item, setItem] = useState(null);
    const [isEdit, setIsEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingList, setLoadingList] = useState(false);

    const [modal_list, setmodal_list] = useState(false);
    const tog_list = () => {
        setmodal_list(!modal_list);
    };

    const [modal_delete, setmodal_delete] = useState(false);
    const tog_delete = () => {
        setmodal_delete(!modal_delete);
    };

    const { userList, baseLoading, baseLoadingList, success, error, message } = useSelector((state) => ({
        userList: state.User.userList,
        baseLoading: state.User.loading,
        baseLoadingList: state.User.loadingList,
        success: state.User.success,
        error: state.User.error,
        message: state.User.message,
    }))

    useEffect(() => {
        if (userList) {
            setHasNextPage(userList.has_next_page);
        }

        if (userList && userList.items?.length > 0) {
            setData(userList);
        } else {
            setData(null);
        }
    }, [userList]);

    useEffect(() => {
        if (success) {
            successnotify(message);
            dispatch(onLoadData({ 'page': page, 'per_page': per_page }));
            setmodal_list(false);
            setmodal_delete(false);
            setItem(null);
            setTimeout(() => {
                dispatch(resetFlag("success", false));
                dispatch(resetFlag("message", null));
            }, 500);
        }
    }, [success, message]);

    useEffect(() => {
        if (error) {
            errornotify(message);
            setTimeout(() => {
                dispatch(resetFlag("error", false));
                dispatch(resetFlag("message", null));
            }, 500);
        }
    }, [error, message]);

    useEffect(() => {
        setLoading(baseLoading);
        setLoadingList(baseLoadingList);
    }, [baseLoading, baseLoadingList])

    useEffect(() => {
        dispatch(onLoadData({ 'page': page, 'per_page': per_page }));
    }, []);

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            id: item?.id,
            first_name: item?.first_name,
            last_name: item?.last_name,
            email: item?.email,
            phone_number: item?.phone_number,
            status: item?.status,
        },
        validationSchema: Yup.object({
            first_name: Yup.string().required("Nama Depan kosong"),
            phone_number: Yup.string().required("Nomor Ponsel kosong"),
        }),
        onSubmit: (values) => {
            if (isEdit) {
                dispatch(onUpdate(values));
            } else {
                dispatch(onCreate(values));
            }
            validation.resetForm();
        },
    });

    const deleteItems = () => {
        dispatch(onDelete({ data: { id: [item.id] } }));
    }

    const filterData = (event) => {
        const keyword = event.target.value;
        setPage(1);
        setOffset(1);
        if (keyword.length > 0) {
            dispatch(onLoadData({ 'page': page, 'per_page': per_page, 'search': keyword }));
        } else {
            dispatch(onLoadData({ 'page': page, 'per_page': per_page }));
        }
    }

    document.title = "User - Bank Sampah Sehati";
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xl={12}>
                            <Card>
                                <CardHeader className="align-items-center d-flex">
                                    <h4 className="card-title mb-0 flex-grow-1">
                                        Data User
                                    </h4>
                                </CardHeader>

                                <CardBody>
                                    <Row className="g-4 mb-3">
                                        <Col className="col-sm-auto">
                                            <div>
                                                <Button
                                                    color="success"
                                                    className="add-btn me-1"
                                                    id="create-btn"
                                                    onClick={() => {
                                                        tog_list();
                                                        setItem(null);
                                                        setIsEdit(false);
                                                        validation.handleReset();
                                                    }}>
                                                    <i className="ri-add-line align-bottom me-1"></i> Tambah
                                                </Button>
                                            </div>
                                        </Col>
                                        <Col className="col-sm">
                                            <div className="d-flex justify-content-sm-end">
                                                <div className="search-box ms-2">
                                                    <input
                                                        type="text"
                                                        className="form-control search"
                                                        placeholder="Cari..."
                                                        onChange={filterData}
                                                    />
                                                    <i className="ri-search-line search-icon"></i>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <div className="table-responsive table-card mt-3">
                                        <table className="table table-hover table-centered align-middle mb-0">
                                            <thead className="text-muted table-light">
                                                <tr>
                                                    <th scope="col">Nama Lengkap</th>
                                                    <th scope="col">Email</th>
                                                    <th scope="col">Nomor Ponsel</th>
                                                    <th scope="col">Status</th>
                                                    <th scope="col">Aksi</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {loadingList ? <tr>
                                                    <td colSpan={5}>
                                                        <div className="d-flex align-items-center justify-content-center">
                                                            <Spinner size="sm" color="primary" />
                                                        </div>
                                                    </td>
                                                </tr> : data && data.items.length > 0 ? data.items.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>{`${item.first_name} ${item.last_name || ''}`}</td>
                                                        <td>{item.email}</td>
                                                        <td>{item.phone_number}</td>
                                                        <td>{item.status === "active" ? <span className="badge badge-soft-success">{props.t(item.status)}</span> : <span className="badge badge-soft-primary">{props.t(item.status)}</span>}</td>
                                                        <td>
                                                            <div className="d-flex gap-2">
                                                                <div className="edit">
                                                                    <button className="btn btn-sm btn-success edit-item-btn"
                                                                        data-bs-toggle="modal" onClick={() => {
                                                                            tog_list();
                                                                            setItem(item);
                                                                            setIsEdit(true);
                                                                        }}>Edit</button>
                                                                </div>
                                                                <div className="remove">
                                                                    <button className="btn btn-sm btn-danger remove-item-btn" onClick={() => {
                                                                        tog_delete();
                                                                        setItem(item);
                                                                    }}>Hapus</button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )) : <tr>
                                                    <td colSpan={5}>
                                                        <div className="d-flex align-item-center justify-content-center">
                                                            Tidak ada data
                                                        </div>
                                                    </td>
                                                </tr>}
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="align-items-center mt-4 pt-2 justify-content-between row text-center text-sm-start">
                                        <div className="col-sm">
                                            <div className="text-muted">
                                                <span className="fw-semibold">{loadingList ? '0' : userList.total_records === 0 ? '0' : per_page > userList.total_records ? `${offset}-${userList.total_records}` : hasNextPage ? `${offset}-${page * per_page}` : `${offset}-${userList.total_records}`}</span> dari <span className="fw-semibold">{loadingList ? '0' : userList.total_records}</span>
                                            </div>
                                        </div>
                                        <div className="col-sm-auto mt-3 mt-sm-0">
                                            <ul className="pagination pagination-separated pagination-sm mb-0 justify-content-center">
                                                <li className={page > 1 ? 'page-item' : 'page-item disabled'}>
                                                    <Button onClick={() => {
                                                        dispatch(onLoadData({ 'page': page - 1, 'per_page': per_page }));
                                                        setPage(page - 1);
                                                        setOffset(offset - per_page);
                                                    }} className="page-link">←</Button>
                                                </li>
                                                <li className={hasNextPage ? 'page-item' : 'page-item disabled'}>
                                                    <Button onClick={() => {
                                                        dispatch(onLoadData({ 'page': page + 1, 'per_page': per_page }));
                                                        setPage(page + 1);
                                                        setOffset(offset + per_page);
                                                    }} className="page-link">→</Button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <ToastContainer />
                </Container>
            </div>

            {/* Add Modal */}
            <Modal isOpen={modal_list} toggle={() => {
                tog_list();
                setItem(null);
                setIsEdit(false);
            }} centered >
                <ModalHeader className="bg-light p-3" toggle={() => {
                    tog_list();
                    setItem(null);
                    setIsEdit(false);
                }}> {isEdit ? 'Edit User' : 'Tambah User'} </ModalHeader>
                <Form onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }} className="tablelist-form">
                    <ModalBody>
                        <div className="mb-3">
                            <label className="form-label">Nama Depan <span className="text-danger">*</span></label>
                            <Input
                                type="text"
                                name="first_name"
                                className="form-control"
                                placeholder="Masukkan Nama Depan"
                                maxLength={255}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.first_name}
                                invalid={
                                    validation.touched.first_name &&
                                        validation.errors.first_name
                                        ? true
                                        : false
                                }
                            />
                            {validation.touched.first_name &&
                                validation.errors.first_name ? (
                                <FormFeedback type="invalid">
                                    {validation.errors.first_name}
                                </FormFeedback>
                            ) : null}
                        </div>

                        <div className="mb-3">
                            <label className="form-label">Nama Belakang</label>
                            <Input type="text" name="last_name" className="form-control" placeholder="Masukkan Nama Belakang" maxLength={255} onChange={validation.handleChange} onBlur={validation.handleBlur} value={validation.values.last_name} />
                        </div>

                        <div className="mb-3">
                            <label className="form-label">Email</label>
                            <Input
                                type="text"
                                name="email"
                                className="form-control"
                                placeholder="Masukkan Email"
                                maxLength={255}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.email}
                            />
                        </div>

                        <div className="mb-3">
                            <label className="form-label">Nomor Ponsel <span className="text-danger">*</span></label>
                            <Input
                                type="text"
                                name="phone_number"
                                className="form-control"
                                placeholder="Masukkan Nomor Ponsel"
                                maxLength={255}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.phone_number}
                                invalid={
                                    validation.touched.phone_number &&
                                        validation.errors.phone_number
                                        ? true
                                        : false
                                }
                            />
                            {validation.touched.phone_number &&
                                validation.errors.phone_number ? (
                                <FormFeedback type="invalid">
                                    {validation.errors.phone_number}
                                </FormFeedback>
                            ) : null}
                        </div>

                        <div className="mb-3">
                            <label className="form-label">Status <span className="text-danger">*</span></label>
                            <select className="form-select" name="status" onChange={validation.handleChange} value={validation.values.status}>
                                <option>Pilih Status</option>
                                <option value="active">Aktif</option>
                                <option value="inactive">Tidak aktif</option>
                            </select>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <button type="button" className="btn btn-light" onClick={() => {
                                tog_list();
                                setItem(null);
                                setIsEdit(false);
                            }}>Tutup</button>
                            <Button
                                disabled={loading ? true : false}
                                color="success"
                                type="submit"
                            >
                                {loading ? (
                                    <Spinner size="sm" className="me-2">
                                        {" "}
                                        Loading...{" "}
                                    </Spinner>
                                ) : null}
                                Simpan
                            </Button>
                        </div>
                    </ModalFooter>
                </Form>
            </Modal>

            {/* Remove Modal */}
            <Modal isOpen={modal_delete} toggle={() => {
                tog_delete();
                setItem(null);
            }} className="modal zoomIn" id="deleteRecordModal" centered >
                <ModalHeader toggle={() => {
                    tog_delete();
                    setItem(null);
                }}></ModalHeader>
                <ModalBody>
                    <div className="mt-2 text-center">
                        <div className="fs-15 mx-4">
                            <h4>Hapus Item {`${item?.first_name} ${item?.last_name || ''}`}</h4>
                            <p className="text-muted mx-4 mb-0">Apakah anda yakin akan menghapus item ini ?</p>
                        </div>
                    </div>
                    <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                        <button type="button" className="btn w-sm btn-light" onClick={() => tog_delete()}>Tutup</button>
                        <Button
                            disabled={loading ? true : false}
                            color="danger"
                            type="button"
                            id="delete-record" onClick={() => deleteItems()}>{loading ? (
                                <Spinner size="sm" className="me-2">
                                    {" "}
                                    Loading...{" "}
                                </Spinner>
                            ) : null}
                            Hapus</Button>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

User.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
};

export default withRouter(withTranslation()(User));
