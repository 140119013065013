import { GET_TRANSACTION, GET_TRANSACTION_SUCCESSFUL, TRANSACTION_API_ERROR, TRANSACTION_API_OK, RESET_TRANSACTION_FLAG, GET_TRANSACTION_DETAILS, GET_TRANSACTION_DETAILS_SUCCESSFUL } from "./actionTypes";

export const getDataTransaction = parameter => {
    return {
        type: GET_TRANSACTION,
        parameter: parameter,
    }
}

export const getDataTransactionSuccessful = data => {
    return {
        type: GET_TRANSACTION_SUCCESSFUL,
        payload: data,
    }
}

export const getDataTransactionDetails = parameter => {
    return {
        type: GET_TRANSACTION_DETAILS,
        parameter: parameter,
    }
}

export const getDataTransactionDetailsSuccessful = data => {
    return {
        type: GET_TRANSACTION_DETAILS_SUCCESSFUL,
        payload: data,
    }
}

export const transactionApiOk = data => {
    return {
        type: TRANSACTION_API_OK,
        payload: data,
    }
}

export const transactionApiError = error => {
    return {
        type: TRANSACTION_API_ERROR,
        payload: error,
    }
}

export const resetTransactionFlag = (flag, value) => {
    return {
        type: RESET_TRANSACTION_FLAG,
        payload: { flag, value },
    }
}