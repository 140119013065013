import { ADD_NEW_BSU, DELETE_BSU, GET_BSU, GET_BSU_SUCCESSFUL, BSU_API_ERROR, BSU_API_OK, RESET_BSU_FLAG, UPDATE_BSU } from "./actionTypes";

export const getDataBsu = parameter => {
    return {
        type: GET_BSU,
        parameter: parameter,
    }
}

export const getDataBsuSuccessful = data => {
    return {
        type: GET_BSU_SUCCESSFUL,
        payload: data,
    }
}

export const addBsu = data => {
    return {
        type: ADD_NEW_BSU,
        payload: data,
    }
}

export const updateBsu = data => {
    return {
        type: UPDATE_BSU,
        payload: data,
    }
}

export const deleteBsu = data => {
    return {
        type: DELETE_BSU,
        payload: data,
    }
}

export const bsuApiOk = data => {
    return {
        type: BSU_API_OK,
        payload: data,
    }
}

export const bsuApiError = error => {
    return {
        type: BSU_API_ERROR,
        payload: error,
    }
}

export const resetBsuFlag = (flag, value) => {
    return {
        type: RESET_BSU_FLAG,
        payload: { flag, value },
    }
}