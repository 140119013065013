import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Navdata = () => {
    const history = useNavigate();
    //state data
    const [isDashboard, setIsDashboard] = useState(false);
    const [isApps, setIsApps] = useState(false);
    const [isAuth, setIsAuth] = useState(false);
    const [isPages, setIsPages] = useState(false);
    const [isBaseUi, setIsBaseUi] = useState(false);
    const [isAdvanceUi, setIsAdvanceUi] = useState(false);
    const [isForms, setIsForms] = useState(false);
    const [isTables, setIsTables] = useState(false);
    const [isCharts, setIsCharts] = useState(false);
    const [isIcons, setIsIcons] = useState(false);
    const [isMaps, setIsMaps] = useState(false);
    const [isMultiLevel, setIsMultiLevel] = useState(false);

    const [iscurrentState, setIscurrentState] = useState('Dashboard');

    function updateIconSidebar(e) {
        if (e && e.target && e.target.getAttribute("subitems")) {
            const ul = document.getElementById("two-column-menu");
            const iconItems = ul.querySelectorAll(".nav-icon.active");
            let activeIconItems = [...iconItems];
            activeIconItems.forEach((item) => {
                item.classList.remove("active");
                var id = item.getAttribute("subitems");
                if (document.getElementById(id))
                    document.getElementById(id).classList.remove("show");
            });
        }
    }

    useEffect(() => {
        document.body.classList.remove('twocolumn-panel');
        if (iscurrentState !== 'Dashboard') {
            setIsDashboard(false);
        }
        if (iscurrentState !== 'Apps') {
            setIsApps(false);
        }
        if (iscurrentState !== 'Auth') {
            setIsAuth(false);
        }
        if (iscurrentState !== 'Pages') {
            setIsPages(false);
        }
        if (iscurrentState !== 'BaseUi') {
            setIsBaseUi(false);
        }
        if (iscurrentState !== 'AdvanceUi') {
            setIsAdvanceUi(false);
        }
        if (iscurrentState !== 'Forms') {
            setIsForms(false);
        }
        if (iscurrentState !== 'Tables') {
            setIsTables(false);
        }
        if (iscurrentState !== 'Charts') {
            setIsCharts(false);
        }
        if (iscurrentState !== 'Icons') {
            setIsIcons(false);
        }
        if (iscurrentState !== 'Maps') {
            setIsMaps(false);
        }
        if (iscurrentState !== 'MuliLevel') {
            setIsMultiLevel(false);
        }
        if (iscurrentState === 'Widgets') {
            history("/widgets");
            document.body.classList.add('twocolumn-panel');
        }
    }, [
        history,
        iscurrentState,
        isDashboard,
        isApps,
        isAuth,
        isPages,
        isBaseUi,
        isAdvanceUi,
        isForms,
        isTables,
        isCharts,
        isIcons,
        isMaps,
        isMultiLevel
    ]);

    const menuItems = [
        {
            label: "Menu",
            isHeader: true,
        },
        {
            id: "dashboard",
            label: "Dasbor",
            icon: "ri-dashboard-2-line",
            link: "/dashboard",
            stateVariables: isDashboard,
            click: function (e) {
                e.preventDefault();
                setIsDashboard(!isDashboard);
                setIscurrentState("Dashboard");
                updateIconSidebar(e);
            },
        },
        {
            id: "bsi",
            label: "Bank Sampah Induk",
            icon: "ri-bank-line",
            link: "/bsi",
            click: function (e) {
                e.preventDefault();
                setIscurrentState("BSI");
            },
        },
        {
            id: "bsu",
            label: "Bank Sampah Unit",
            icon: "ri-building-line",
            link: "/bsu",
            click: function (e) {
                e.preventDefault();
                setIscurrentState("BSU");
            },
        },
        {
            id: "trash",
            label: "Data Sampah",
            icon: "ri-seedling-line",
            link: "/trash",
            click: function (e) {
                e.preventDefault();
                setIscurrentState("Trash");
            },
        },
        {
            id: "customer",
            label: "Nasabah",
            icon: "ri-user-line",
            link: "/customer",
            click: function (e) {
                e.preventDefault();
                setIscurrentState("Customer");
            },
        },
        {
            id: "transaction",
            label: "Transaksi",
            icon: "ri-exchange-box-line",
            link: "/transaction",
            click: function (e) {
                e.preventDefault();
                setIscurrentState("Transaction");
            },
        },
        {
            id: "balance",
            label: "Data Saldo",
            icon: "ri-wallet-line",
            link: "/balance-data",
            click: function (e) {
                e.preventDefault();
                setIscurrentState("Balance");
            },
        },
        {
            id: "withdrawal",
            label: "Penarikan Dana",
            icon: "ri-arrow-down",
            link: "/withdrawal",
            click: function (e) {
                e.preventDefault();
                setIscurrentState("Withdrawal");
            },
        },
        {
            id: "user",
            label: "User",
            icon: "ri-user-line",
            link: "/user",
            click: function (e) {
                e.preventDefault();
                setIscurrentState("User");
            },
        },
    ];
    return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;