import { ADD_NEW_USER, DELETE_USER, GET_USER, GET_USER_SUCCESSFUL, USER_API_ERROR, USER_API_OK, RESET_USER_FLAG, UPDATE_USER } from "./actionTypes";

export const getDataUser = parameter => {
    return {
        type: GET_USER,
        parameter: parameter,
    }
}

export const getDataUserSuccessful = data => {
    return {
        type: GET_USER_SUCCESSFUL,
        payload: data,
    }
}

export const addUser = data => {
    return {
        type: ADD_NEW_USER,
        payload: data,
    }
}

export const updateUser = data => {
    return {
        type: UPDATE_USER,
        payload: data,
    }
}

export const deleteUser = data => {
    return {
        type: DELETE_USER,
        payload: data,
    }
}

export const userApiOk = data => {
    return {
        type: USER_API_OK,
        payload: data,
    }
}

export const userApiError = error => {
    return {
        type: USER_API_ERROR,
        payload: error,
    }
}

export const resetUserFlag = (flag, value) => {
    return {
        type: RESET_USER_FLAG,
        payload: { flag, value },
    }
}