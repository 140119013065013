import { GET_PROVINCE, GET_PROVINCE_SUCCESSFUL, GET_REGENCY, GET_REGENCY_SUCCESSFUL, GET_SUBDISTRICT, GET_SUBDISTRICT_SUCCESSFUL, GET_VILLAGE, GET_VILLAGE_SUCCESSFUL, REGION_API_ERROR, REGION_API_OK, RESET_REGION_FLAG } from "./actionTypes";

const INIT_STATE = {
    provinceList: [],
    regencyList: [],
    subdistrictList: [],
    villageList: [],
    loadingProvince: false,
    loadingRegency: false,
    loadingSubdistrict: false,
    loadingVillage: false,
    success: false,
    error: false,
    message: null,
};

const Region = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_PROVINCE:
            return {
                ...state,
                loadingProvince: true,
                provinceList: []
            };

        case GET_PROVINCE_SUCCESSFUL:
            return {
                ...state,
                loadingProvince: false,
                provinceList: action.payload
            };

        case GET_REGENCY:
            return {
                ...state,
                loadingRegency: true,
                regencyList: []
            };

        case GET_REGENCY_SUCCESSFUL:
            return {
                ...state,
                loadingRegency: false,
                regencyList: action.payload
            };

        case GET_SUBDISTRICT:
            return {
                ...state,
                loadingSubdistrict: true,
                subdistrictList: []
            };

        case GET_SUBDISTRICT_SUCCESSFUL:
            return {
                ...state,
                loadingSubdistrict: false,
                subdistrictList: action.payload
            };

        case GET_VILLAGE:
            return {
                ...state,
                loadingVillage: true,
                villageList: []
            };

        case GET_VILLAGE_SUCCESSFUL:
            return {
                ...state,
                loadingVillage: false,
                villageList: action.payload
            };

        case REGION_API_OK:
            return {
                ...state,
                loadingProvince: false,
                loadingRegency: false,
                loadingSubdistrict: false,
                loadingVillage: false,
                success: true,
                message: action.payload
            };

        case REGION_API_ERROR:
            return {
                ...state,
                loadingProvince: false,
                loadingRegency: false,
                loadingSubdistrict: false,
                loadingVillage: false,
                success: false,
                error: true,
                message: action.payload
            };

        case RESET_REGION_FLAG:
            var flag = action.payload.flag;
            var value = action.payload.value;
            var flags = {};
            flags[flag] = value;

            return {
                ...state,
                ...flags,
            };
        default:
            return state;
    }
}

export default Region;