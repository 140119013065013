import { call, put, takeEvery } from "redux-saga/effects";
import { getDataProvinceSuccessful, getDataRegencySuccessful, getDataSubdistrictSuccessful, getDataVillageSuccessful, regionApiError } from "./actions";
import { getProvince, getRegency, getSubdistrict, getVillage } from "../../helpers/backend_helper";
import { GET_PROVINCE, GET_REGENCY, GET_SUBDISTRICT, GET_VILLAGE, } from "./actionTypes";

function* fetchDataProvince({ parameter }) {
    try {
        const response = yield call(getProvince, parameter)
        yield put(getDataProvinceSuccessful(response))
    } catch (error) {
        yield put(regionApiError(error))
    }
}

function* fetchDataRegency({ parameter }) {
    try {
        const response = yield call(getRegency, parameter)
        yield put(getDataRegencySuccessful(response))
    } catch (error) {
        yield put(regionApiError(error))
    }
}

function* fetchDataSubdistrict({ parameter }) {
    try {
        const response = yield call(getSubdistrict, parameter)
        yield put(getDataSubdistrictSuccessful(response))
    } catch (error) {
        yield put(regionApiError(error))
    }
}

function* fetchDataVillage({ parameter }) {
    try {
        const response = yield call(getVillage, parameter)
        yield put(getDataVillageSuccessful(response))
    } catch (error) {
        yield put(regionApiError(error))
    }
}

function* regionSaga() {
    yield takeEvery(GET_PROVINCE, fetchDataProvince)
    yield takeEvery(GET_REGENCY, fetchDataRegency)
    yield takeEvery(GET_SUBDISTRICT, fetchDataSubdistrict)
    yield takeEvery(GET_VILLAGE, fetchDataVillage)

}

export default regionSaga;