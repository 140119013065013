import { ADD_NEW_CUSTOMER, DELETE_CUSTOMER, GET_CUSTOMER, GET_CUSTOMER_SUCCESSFUL, CUSTOMER_API_ERROR, CUSTOMER_API_OK, RESET_CUSTOMER_FLAG, UPDATE_CUSTOMER } from "./actionTypes";

export const getDataCustomer = parameter => {
    return {
        type: GET_CUSTOMER,
        parameter: parameter,
    }
}

export const getDataCustomerSuccessful = data => {
    return {
        type: GET_CUSTOMER_SUCCESSFUL,
        payload: data,
    }
}

export const addCustomer = data => {
    return {
        type: ADD_NEW_CUSTOMER,
        payload: data,
    }
}

export const updateCustomer = data => {
    return {
        type: UPDATE_CUSTOMER,
        payload: data,
    }
}

export const deleteCustomer = data => {
    return {
        type: DELETE_CUSTOMER,
        payload: data,
    }
}

export const customerApiOk = data => {
    return {
        type: CUSTOMER_API_OK,
        payload: data,
    }
}

export const customerApiError = error => {
    return {
        type: CUSTOMER_API_ERROR,
        payload: error,
    }
}

export const resetCustomerFlag = (flag, value) => {
    return {
        type: RESET_CUSTOMER_FLAG,
        payload: { flag, value },
    }
}