import { ADD_NEW_COLLECTOR, DELETE_COLLECTOR, GET_COLLECTOR, GET_COLLECTOR_SUCCESSFUL, COLLECTOR_API_ERROR, COLLECTOR_API_OK, RESET_COLLECTOR_FLAG, UPDATE_COLLECTOR } from "./actionTypes";

export const getDataCollector = parameter => {
    return {
        type: GET_COLLECTOR,
        parameter: parameter,
    }
}

export const getDataCollectorSuccessful = data => {
    return {
        type: GET_COLLECTOR_SUCCESSFUL,
        payload: data,
    }
}

export const addCollector = data => {
    return {
        type: ADD_NEW_COLLECTOR,
        payload: data,
    }
}

export const updateCollector = data => {
    return {
        type: UPDATE_COLLECTOR,
        payload: data,
    }
}

export const deleteCollector = data => {
    return {
        type: DELETE_COLLECTOR,
        payload: data,
    }
}

export const collectorApiOk = data => {
    return {
        type: COLLECTOR_API_OK,
        payload: data,
    }
}

export const collectorApiError = error => {
    return {
        type: COLLECTOR_API_ERROR,
        payload: error,
    }
}

export const resetCollectorFlag = (flag, value) => {
    return {
        type: RESET_COLLECTOR_FLAG,
        payload: { flag, value },
    }
}