export const GET_USER = "GET_USER";
export const GET_USER_SUCCESSFUL = "GET_USER_SUCCESSFUL";

export const ADD_NEW_USER = "ADD_NEW_USER";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_USER = "DELETE_USER";

export const USER_API_OK = "USER_API_OK";
export const USER_API_ERROR = "USER_API_ERROR";

export const RESET_USER_FLAG = "RESET_USER_FLAG";