export const GET_TRASH = "GET_TRASH";
export const GET_TRASH_SUCCESSFUL = "GET_TRASH_SUCCESSFUL";

export const ADD_NEW_TRASH = "ADD_NEW_TRASH";
export const UPDATE_TRASH = "UPDATE_TRASH";
export const DELETE_TRASH = "DELETE_TRASH";

export const TRASH_API_OK = "TRASH_API_OK";
export const TRASH_API_ERROR = "TRASH_API_ERROR";

export const RESET_TRASH_FLAG = "RESET_TRASH_FLAG";