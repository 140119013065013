import React, { useEffect, useState } from "react";
import {
    Card,
    Col,
    Container,
    Input,
    Label,
    Row,
    Button,
    Form,
    FormFeedback,
    Alert,
    Spinner,
} from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link } from "react-router-dom";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// actions
import { loginUser, resetLoginFlag } from "../../store/actions";

import withRouter from "../../Components/Common/withRouter";

const Login = (props) => {
    const dispatch = useDispatch();

    const { errorMsg, loading, error } = useSelector((state) => ({
        errorMsg: state.Login.errorMsg,
        loading: state.Login.loading,
        error: state.Login.error,
    }));
    const [passwordShow, setPasswordShow] = useState(false);

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            email: "",
            password: "",
        },
        validationSchema: Yup.object({
            email: Yup.string().required("Email kosong"),
            password: Yup.string().required("Password kosong"),
        }),
        onSubmit: (values) => {
            dispatch(loginUser(values, props.router.navigate));
        },
    });

    useEffect(() => {
        if (error) {
            setTimeout(() => {
                dispatch(resetLoginFlag());
            }, 3000);
        }
    }, [dispatch, error, errorMsg]);

    document.title = "Masuk - Bank Sampah Sehati";
    return (
        <React.Fragment>
            <div className="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
                <div className="bg-overlay"></div>
                <div className="auth-page-content overflow-hidden pt-lg-5">
                    <Container>
                        <Row className="justify-content-center g-0">
                            <Col lg={6}>
                                <Card className="overflow-hidden">
                                    <div className="p-lg-5 p-4">
                                        <div>
                                            <h5 className="text-primary">Selamat datang !</h5>
                                            <p className="text-muted">
                                                Masukkan email dan password anda.
                                            </p>
                                        </div>

                                        {errorMsg && errorMsg ? (
                                            <Alert color="danger"> {errorMsg} </Alert>
                                        ) : null}

                                        <div className="mt-4">
                                            <Form
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    validation.handleSubmit();
                                                    return false;
                                                }}
                                                action="#"
                                            >
                                                <div className="mb-3">
                                                    <Label htmlFor="email" className="form-label">
                                                        Email
                                                    </Label>
                                                    <Input
                                                        name="email"
                                                        className="form-control"
                                                        placeholder="Masukkan email"
                                                        type="email"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        invalid={
                                                            validation.touched.email &&
                                                                validation.errors.email
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.email &&
                                                        validation.errors.email ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.email}
                                                        </FormFeedback>
                                                    ) : null}
                                                </div>

                                                <div className="mb-3">
                                                    <div className="float-end">
                                                        <Link
                                                            to="/auth-pass-reset-cover"
                                                            className="text-muted"
                                                        >
                                                            Lupa password?
                                                        </Link>
                                                    </div>
                                                    <Label
                                                        className="form-label"
                                                        htmlFor="password-input"
                                                    >
                                                        Password
                                                    </Label>
                                                    <div className="position-relative auth-pass-inputgroup mb-3">
                                                        <Input
                                                            id="password-input"
                                                            name="password"
                                                            type={passwordShow ? "text" : "password"}
                                                            className="form-control pe-5 password-input"
                                                            placeholder="Masukkan password"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            invalid={
                                                                validation.touched.password &&
                                                                    validation.errors.password
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                        {validation.touched.password &&
                                                            validation.errors.password ? (
                                                            <FormFeedback type="invalid">
                                                                {validation.errors.password}
                                                            </FormFeedback>
                                                        ) : null}
                                                        <button
                                                            className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                                                            type="button"
                                                            id="password-addon"
                                                            onClick={() => setPasswordShow(!passwordShow)}
                                                        >
                                                            <i className="ri-eye-off-fill align-middle"></i>
                                                        </button>
                                                    </div>
                                                </div>

                                                <div className="form-check">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value=""
                                                        id="auth-remember-check"
                                                    />
                                                    <Label
                                                        className="form-check-label"
                                                        htmlFor="auth-remember-check"
                                                    >
                                                        Ingat saya
                                                    </Label>
                                                </div>

                                                <div className="mt-4">
                                                    <Button
                                                        disabled={error ? null : loading ? true : false}
                                                        color="success"
                                                        className="w-100"
                                                        type="submit"
                                                    >
                                                        {error ? null : loading ? (
                                                            <Spinner size="sm" className="me-2">
                                                                {" "}
                                                                Loading...{" "}
                                                            </Spinner>
                                                        ) : null}
                                                        Masuk
                                                    </Button>
                                                </div>
                                            </Form>
                                        </div>

                                        {/* <div className="mt-5 text-center">
                                            <p className="mb-0">
                                                Belum punya akun ?{" "}
                                                <Link
                                                    to="/register"
                                                    className="fw-semibold text-primary text-decoration-underline"
                                                >
                                                    Daftar
                                                </Link>
                                            </p>
                                        </div> */}
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <footer className="footer">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="text-center">
                                    <p className="mb-0">
                                        &copy; {new Date().getFullYear()} Bank Sampah Sehati. All Rights Reserved
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </footer>
            </div>
        </React.Fragment>
    );
};

export default withRouter(Login);
