import { GET_TRANSACTION, GET_TRANSACTION_SUCCESSFUL, TRANSACTION_API_ERROR, TRANSACTION_API_OK, RESET_TRANSACTION_FLAG, GET_TRANSACTION_DETAILS, GET_TRANSACTION_DETAILS_SUCCESSFUL } from "./actionTypes";

const INIT_STATE = {
    transactionList: [],
    details: null,
    loading: false,
    loadingList: false,
    success: false,
    error: false,
    message: null,
};

const Transaction = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_TRANSACTION:
            return {
                ...state,
                loadingList: true,
                transactionList: []
            };

        case GET_TRANSACTION_SUCCESSFUL:
            return {
                ...state,
                loadingList: false,
                transactionList: action.payload
            };

        case GET_TRANSACTION_DETAILS:
            return {
                ...state,
                loading: true,
                details: []
            };

        case GET_TRANSACTION_DETAILS_SUCCESSFUL:
            return {
                ...state,
                loading: false,
                details: action.payload
            };

        case TRANSACTION_API_OK:
            return {
                ...state,
                loading: false,
                success: true,
                message: action.payload
            };

        case TRANSACTION_API_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: true,
                message: action.payload
            };

        case RESET_TRANSACTION_FLAG:
            var flag = action.payload.flag;
            var value = action.payload.value;
            var flags = {};
            flags[flag] = value;

            return {
                ...state,
                ...flags,
            };
        default:
            return state;
    }
}

export default Transaction;