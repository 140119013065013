import { all, fork } from "redux-saga/effects";
//layout
import LayoutSaga from "./layouts/saga";
//Auth
import AuthSaga from "./auth/login/saga";

import regionSaga from "./region/saga";
import userSaga from "./user/saga";
import collectorSaga from "./collector/saga";
import bsuSaga from "./bsu/saga";
import trashSaga from "./trash/saga";
import customerSaga from "./customer/saga";
import transactionSaga from "./transaction/saga";

export default function* rootSaga() {
  yield all([
    //public
    fork(LayoutSaga),
    fork(AuthSaga),
    fork(regionSaga),
    fork(userSaga),
    fork(collectorSaga),
    fork(bsuSaga),
    fork(trashSaga),
    fork(customerSaga),
    fork(transactionSaga),
  ]);
}
