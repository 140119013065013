import { ADD_NEW_CUSTOMER, DELETE_CUSTOMER, GET_CUSTOMER, GET_CUSTOMER_SUCCESSFUL, CUSTOMER_API_ERROR, CUSTOMER_API_OK, RESET_CUSTOMER_FLAG, UPDATE_CUSTOMER } from "./actionTypes";

const INIT_STATE = {
    customerList: [],
    loading: false,
    loadingList: false,
    success: false,
    error: false,
    message: null,
};

const Customer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_CUSTOMER:
            return {
                ...state,
                loadingList: true,
                customerList: []
            };

        case GET_CUSTOMER_SUCCESSFUL:
            return {
                ...state,
                loadingList: false,
                customerList: action.payload
            };

        case ADD_NEW_CUSTOMER:
            return {
                ...state,
                loading: true,
            };

        case UPDATE_CUSTOMER:
            return {
                ...state,
                loading: true,
            };

        case DELETE_CUSTOMER:
            return {
                ...state,
                loading: true,
            };

        case CUSTOMER_API_OK:
            return {
                ...state,
                loading: false,
                success: true,
                message: action.payload
            };

        case CUSTOMER_API_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: true,
                message: action.payload
            };

        case RESET_CUSTOMER_FLAG:
            var flag = action.payload.flag;
            var value = action.payload.value;
            var flags = {};
            flags[flag] = value;

            return {
                ...state,
                ...flags,
            };
        default:
            return state;
    }
}

export default Customer;