import { ADD_NEW_BSU, DELETE_BSU, GET_BSU, GET_BSU_SUCCESSFUL, BSU_API_ERROR, BSU_API_OK, RESET_BSU_FLAG, UPDATE_BSU } from "./actionTypes";

const INIT_STATE = {
    bsuList: [],
    loading: false,
    loadingList: false,
    success: false,
    error: false,
    message: null,
};

const Bsu = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_BSU:
            return {
                ...state,
                loadingList: true,
                bsuList: []
            };

        case GET_BSU_SUCCESSFUL:
            return {
                ...state,
                loadingList: false,
                bsuList: action.payload
            };

        case ADD_NEW_BSU:
            return {
                ...state,
                loading: true,
            };

        case UPDATE_BSU:
            return {
                ...state,
                loading: true,
            };

        case DELETE_BSU:
            return {
                ...state,
                loading: true,
            };

        case BSU_API_OK:
            return {
                ...state,
                loading: false,
                success: true,
                message: action.payload
            };

        case BSU_API_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: true,
                message: action.payload
            };

        case RESET_BSU_FLAG:
            var flag = action.payload.flag;
            var value = action.payload.value;
            var flags = {};
            flags[flag] = value;

            return {
                ...state,
                ...flags,
            };
        default:
            return state;
    }
}

export default Bsu;