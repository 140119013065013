import { ADD_NEW_TRASH, DELETE_TRASH, GET_TRASH, GET_TRASH_SUCCESSFUL, TRASH_API_ERROR, TRASH_API_OK, RESET_TRASH_FLAG, UPDATE_TRASH } from "./actionTypes";

const INIT_STATE = {
    trashList: [],
    loading: false,
    loadingList: false,
    success: false,
    error: false,
    message: null,
};

const Trash = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_TRASH:
            return {
                ...state,
                loadingList: true,
                trashList: []
            };

        case GET_TRASH_SUCCESSFUL:
            return {
                ...state,
                loadingList: false,
                trashList: action.payload
            };

        case ADD_NEW_TRASH:
            return {
                ...state,
                loading: true,
            };

        case UPDATE_TRASH:
            return {
                ...state,
                loading: true,
            };

        case DELETE_TRASH:
            return {
                ...state,
                loading: true,
            };

        case TRASH_API_OK:
            return {
                ...state,
                loading: false,
                success: true,
                message: action.payload
            };

        case TRASH_API_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: true,
                message: action.payload
            };

        case RESET_TRASH_FLAG:
            var flag = action.payload.flag;
            var value = action.payload.value;
            var flags = {};
            flags[flag] = value;

            return {
                ...state,
                ...flags,
            };
        default:
            return state;
    }
}

export default Trash;