import { ADD_NEW_COLLECTOR, DELETE_COLLECTOR, GET_COLLECTOR, GET_COLLECTOR_SUCCESSFUL, COLLECTOR_API_ERROR, COLLECTOR_API_OK, RESET_COLLECTOR_FLAG, UPDATE_COLLECTOR } from "./actionTypes";

const INIT_STATE = {
    collectorList: [],
    loading: false,
    loadingList: false,
    success: false,
    error: false,
    message: null,
};

const Collector = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_COLLECTOR:
            return {
                ...state,
                loadingList: true,
                collectorList: []
            };

        case GET_COLLECTOR_SUCCESSFUL:
            return {
                ...state,
                loadingList: false,
                collectorList: action.payload
            };

        case ADD_NEW_COLLECTOR:
            return {
                ...state,
                loading: true,
            };

        case UPDATE_COLLECTOR:
            return {
                ...state,
                loading: true,
            };

        case DELETE_COLLECTOR:
            return {
                ...state,
                loading: true,
            };

        case COLLECTOR_API_OK:
            return {
                ...state,
                loading: false,
                success: true,
                message: action.payload
            };

        case COLLECTOR_API_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: true,
                message: action.payload
            };

        case RESET_COLLECTOR_FLAG:
            var flag = action.payload.flag;
            var value = action.payload.value;
            var flags = {};
            flags[flag] = value;

            return {
                ...state,
                ...flags,
            };
        default:
            return state;
    }
}

export default Collector;