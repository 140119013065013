import { call, put, takeEvery } from "redux-saga/effects";
import { getDataTransactionDetailsSuccessful, getDataTransactionSuccessful, transactionApiError, transactionApiOk } from "./actions";
import { getTransactionDetails, getTransaction as onLoad } from "../../helpers/backend_helper";
import { GET_TRANSACTION, GET_TRANSACTION_DETAILS } from "./actionTypes";

function* fetchData({ parameter }) {
    try {
        const response = yield call(onLoad, parameter)
        yield put(getDataTransactionSuccessful(response))
    } catch (error) {
        yield put(transactionApiError(error))
    }
}

function* fetchDetails({ parameter }) {
    try {
        const response = yield call(getTransactionDetails, parameter)
        yield put(getDataTransactionDetailsSuccessful(response))
    } catch (error) {
        yield put(transactionApiError(error))
    }
}

function* transactionSaga() {
    yield takeEvery(GET_TRANSACTION, fetchData)
    yield takeEvery(GET_TRANSACTION_DETAILS, fetchDetails)
}

export default transactionSaga;