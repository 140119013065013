import { call, put, takeEvery } from "redux-saga/effects";
import { getDataBsuSuccessful, bsuApiError, bsuApiOk } from "./actions";
import { createBsu as onCreate, deleteBsu as onDelete, getBsu as onLoad, updateBsu as onUpdate } from "../../helpers/backend_helper";
import { ADD_NEW_BSU, DELETE_BSU, GET_BSU, UPDATE_BSU } from "./actionTypes";

function* fetchData({ parameter }) {
    try {
        const response = yield call(onLoad, parameter)
        yield put(getDataBsuSuccessful(response))
    } catch (error) {
        yield put(bsuApiError(error))
    }
}

function* addItem({ payload }) {
    try {
        const response = yield call(onCreate, payload)
        yield put(bsuApiOk(response.message))
    } catch (error) {
        yield put(bsuApiError(error))
    }
}

function* updateItems({ payload }) {
    try {
        const response = yield call(onUpdate, payload)
        yield put(bsuApiOk(response.message))
    } catch (error) {
        yield put(bsuApiError(error))
    }
}

function* deleteItems({ payload }) {
    try {
        const response = yield call(onDelete, payload)
        yield put(bsuApiOk(response.message))
    } catch (error) {
        yield put(bsuApiError(error))
    }
}

function* bsuSaga() {
    yield takeEvery(GET_BSU, fetchData)
    yield takeEvery(ADD_NEW_BSU, addItem)
    yield takeEvery(UPDATE_BSU, updateItems)
    yield takeEvery(DELETE_BSU, deleteItems)
}

export default bsuSaga;