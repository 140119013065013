import { GET_PROVINCE, GET_PROVINCE_SUCCESSFUL, GET_REGENCY, GET_REGENCY_SUCCESSFUL, GET_SUBDISTRICT, GET_SUBDISTRICT_SUCCESSFUL, GET_VILLAGE, GET_VILLAGE_SUCCESSFUL, REGION_API_ERROR, REGION_API_OK, RESET_REGION_FLAG } from "./actionTypes"

export const getDataProvince = parameter => {
    return {
        type: GET_PROVINCE,
        parameter: parameter,
    }
}

export const getDataProvinceSuccessful = data => {
    return {
        type: GET_PROVINCE_SUCCESSFUL,
        payload: data,
    }
}

export const getDataRegency = parameter => {
    return {
        type: GET_REGENCY,
        parameter: parameter,
    }
}

export const getDataRegencySuccessful = data => {
    return {
        type: GET_REGENCY_SUCCESSFUL,
        payload: data,
    }
}

export const getDataSubdistrict = parameter => {
    return {
        type: GET_SUBDISTRICT,
        parameter: parameter,
    }
}

export const getDataSubdistrictSuccessful = data => {
    return {
        type: GET_SUBDISTRICT_SUCCESSFUL,
        payload: data,
    }
}

export const getDataVillage = parameter => {
    return {
        type: GET_VILLAGE,
        parameter: parameter,
    }
}

export const getDataVillageSuccessful = data => {
    return {
        type: GET_VILLAGE_SUCCESSFUL,
        payload: data,
    }
}

export const regionApiOk = data => {
    return {
        type: REGION_API_OK,
        payload: data,
    }
}

export const regionApiError = error => {
    return {
        type: REGION_API_ERROR,
        payload: error,
    }
}

export const resetRegionFlag = (flag, value) => {
    return {
        type: RESET_REGION_FLAG,
        payload: { flag, value },
    }
}
