export const GET_CUSTOMER = "GET_CUSTOMER";
export const GET_CUSTOMER_SUCCESSFUL = "GET_CUSTOMER_SUCCESSFUL";

export const ADD_NEW_CUSTOMER = "ADD_NEW_CUSTOMER";
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";
export const DELETE_CUSTOMER = "DELETE_CUSTOMER";

export const CUSTOMER_API_OK = "CUSTOMER_API_OK";
export const CUSTOMER_API_ERROR = "CUSTOMER_API_ERROR";

export const RESET_CUSTOMER_FLAG = "RESET_CUSTOMER_FLAG";