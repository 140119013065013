import { ADD_NEW_TRASH, DELETE_TRASH, GET_TRASH, GET_TRASH_SUCCESSFUL, TRASH_API_ERROR, TRASH_API_OK, RESET_TRASH_FLAG, UPDATE_TRASH } from "./actionTypes";

export const getDataTrash = parameter => {
    return {
        type: GET_TRASH,
        parameter: parameter,
    }
}

export const getDataTrashSuccessful = data => {
    return {
        type: GET_TRASH_SUCCESSFUL,
        payload: data,
    }
}

export const addTrash = data => {
    return {
        type: ADD_NEW_TRASH,
        payload: data,
    }
}

export const updateTrash = data => {
    return {
        type: UPDATE_TRASH,
        payload: data,
    }
}

export const deleteTrash = data => {
    return {
        type: DELETE_TRASH,
        payload: data,
    }
}

export const trashApiOk = data => {
    return {
        type: TRASH_API_OK,
        payload: data,
    }
}

export const trashApiError = error => {
    return {
        type: TRASH_API_ERROR,
        payload: error,
    }
}

export const resetTrashFlag = (flag, value) => {
    return {
        type: RESET_TRASH_FLAG,
        payload: { flag, value },
    }
}