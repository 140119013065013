import { call, put, takeEvery } from "redux-saga/effects";
import { getDataCustomerSuccessful, customerApiError, customerApiOk } from "./actions";
import { createCustomer as onCreate, deleteCustomer as onDelete, getCustomer as onLoad, updateCustomer as onUpdate } from "../../helpers/backend_helper";
import { ADD_NEW_CUSTOMER, DELETE_CUSTOMER, GET_CUSTOMER, UPDATE_CUSTOMER } from "./actionTypes";

function* fetchData({ parameter }) {
    try {
        const response = yield call(onLoad, parameter)
        yield put(getDataCustomerSuccessful(response))
    } catch (error) {
        yield put(customerApiError(error))
    }
}

function* addItem({ payload }) {
    try {
        const response = yield call(onCreate, payload)
        yield put(customerApiOk(response.message))
    } catch (error) {
        yield put(customerApiError(error))
    }
}

function* updateItems({ payload }) {
    try {
        const response = yield call(onUpdate, payload)
        yield put(customerApiOk(response.message))
    } catch (error) {
        yield put(customerApiError(error))
    }
}

function* deleteItems({ payload }) {
    try {
        const response = yield call(onDelete, payload)
        yield put(customerApiOk(response.message))
    } catch (error) {
        yield put(customerApiError(error))
    }
}

function* customerSaga() {
    yield takeEvery(GET_CUSTOMER, fetchData)
    yield takeEvery(ADD_NEW_CUSTOMER, addItem)
    yield takeEvery(UPDATE_CUSTOMER, updateItems)
    yield takeEvery(DELETE_CUSTOMER, deleteItems)
}

export default customerSaga;