export const GET_PROVINCE = "GET_PROVINCE";
export const GET_PROVINCE_SUCCESSFUL = "GET_PROVINCE_SUCCESSFUL";

export const GET_REGENCY = "GET_REGENCY";
export const GET_REGENCY_SUCCESSFUL = "GET_REGENCY_SUCCESSFUL";

export const GET_SUBDISTRICT = "GET_SUBDISTRICT";
export const GET_SUBDISTRICT_SUCCESSFUL = "GET_SUBDISTRICT_SUCCESSFUL";

export const GET_VILLAGE = "GET_VILLAGE";
export const GET_VILLAGE_SUCCESSFUL = "GET_VILLAGE_SUCCESSFUL";

export const REGION_API_OK = "REGION_API_OK";
export const REGION_API_ERROR = "REGION_API_ERROR";

export const RESET_REGION_FLAG = "RESET_REGION_FLAG";