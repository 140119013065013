import { ADD_NEW_USER, DELETE_USER, GET_USER, GET_USER_SUCCESSFUL, USER_API_ERROR, USER_API_OK, RESET_USER_FLAG, UPDATE_USER } from "./actionTypes";

const INIT_STATE = {
    userList: [],
    loading: false,
    loadingList: false,
    success: false,
    error: false,
    message: null,
};

const User = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_USER:
            return {
                ...state,
                loadingList: true,
                userList: []
            };

        case GET_USER_SUCCESSFUL:
            return {
                ...state,
                loadingList: false,
                userList: action.payload
            };

        case ADD_NEW_USER:
            return {
                ...state,
                loading: true,
            };

        case UPDATE_USER:
            return {
                ...state,
                loading: true,
            };

        case DELETE_USER:
            return {
                ...state,
                loading: true,
            };

        case USER_API_OK:
            return {
                ...state,
                loading: false,
                success: true,
                message: action.payload
            };

        case USER_API_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: true,
                message: action.payload
            };

        case RESET_USER_FLAG:
            var flag = action.payload.flag;
            var value = action.payload.value;
            var flags = {};
            flags[flag] = value;

            return {
                ...state,
                ...flags,
            };
        default:
            return state;
    }
}

export default User;