import { call, put, takeEvery } from "redux-saga/effects";
import { getDataUserSuccessful, userApiError, userApiOk } from "./actions";
import { createUser as onCreate, deleteUser as onDelete, getUser as onLoad, updateUser as onUpdate } from "../../helpers/backend_helper";
import { ADD_NEW_USER, DELETE_USER, GET_USER, UPDATE_USER } from "./actionTypes";

function* fetchData({ parameter }) {
    try {
        const response = yield call(onLoad, parameter)
        yield put(getDataUserSuccessful(response))
    } catch (error) {
        yield put(userApiError(error))
    }
}

function* addItem({ payload }) {
    try {
        const response = yield call(onCreate, payload)
        yield put(userApiOk(response.message))
    } catch (error) {
        yield put(userApiError(error))
    }
}

function* updateItems({ payload }) {
    try {
        const response = yield call(onUpdate, payload)
        yield put(userApiOk(response.message))
    } catch (error) {
        yield put(userApiError(error))
    }
}

function* deleteItems({ payload }) {
    try {
        const response = yield call(onDelete, payload)
        yield put(userApiOk(response.message))
    } catch (error) {
        yield put(userApiError(error))
    }
}

function* userSaga() {
    yield takeEvery(GET_USER, fetchData)
    yield takeEvery(ADD_NEW_USER, addItem)
    yield takeEvery(UPDATE_USER, updateItems)
    yield takeEvery(DELETE_USER, deleteItems)
}

export default userSaga;