export const GET_COLLECTOR = "GET_COLLECTOR";
export const GET_COLLECTOR_SUCCESSFUL = "GET_COLLECTOR_SUCCESSFUL";

export const ADD_NEW_COLLECTOR = "ADD_NEW_COLLECTOR";
export const UPDATE_COLLECTOR = "UPDATE_COLLECTOR";
export const DELETE_COLLECTOR = "DELETE_COLLECTOR";

export const COLLECTOR_API_OK = "COLLECTOR_API_OK";
export const COLLECTOR_API_ERROR = "COLLECTOR_API_ERROR";

export const RESET_COLLECTOR_FLAG = "RESET_COLLECTOR_FLAG";