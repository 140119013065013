import { call, put, takeEvery } from "redux-saga/effects";
import { getDataTrashSuccessful, trashApiError, trashApiOk } from "./actions";
import { createTrash as onCreate, deleteTrash as onDelete, getTrash as onLoad, updateTrash as onUpdate } from "../../helpers/backend_helper";
import { ADD_NEW_TRASH, DELETE_TRASH, GET_TRASH, UPDATE_TRASH } from "./actionTypes";

function* fetchData({ parameter }) {
    try {
        const response = yield call(onLoad, parameter)
        yield put(getDataTrashSuccessful(response))
    } catch (error) {
        yield put(trashApiError(error))
    }
}

function* addItem({ payload }) {
    try {
        const response = yield call(onCreate, payload)
        yield put(trashApiOk(response.message))
    } catch (error) {
        yield put(trashApiError(error))
    }
}

function* updateItems({ payload }) {
    try {
        const response = yield call(onUpdate, payload)
        yield put(trashApiOk(response.message))
    } catch (error) {
        yield put(trashApiError(error))
    }
}

function* deleteItems({ payload }) {
    try {
        const response = yield call(onDelete, payload)
        yield put(trashApiOk(response.message))
    } catch (error) {
        yield put(trashApiError(error))
    }
}

function* trashSaga() {
    yield takeEvery(GET_TRASH, fetchData)
    yield takeEvery(ADD_NEW_TRASH, addItem)
    yield takeEvery(UPDATE_TRASH, updateItems)
    yield takeEvery(DELETE_TRASH, deleteItems)
}

export default trashSaga;