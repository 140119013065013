import { call, put, takeEvery } from "redux-saga/effects";
import { getDataCollectorSuccessful, collectorApiError, collectorApiOk } from "./actions";
import { createCollector as onCreate, deleteCollector as onDelete, getCollector as onLoad, updateCollector as onUpdate } from "../../helpers/backend_helper";
import { ADD_NEW_COLLECTOR, DELETE_COLLECTOR, GET_COLLECTOR, UPDATE_COLLECTOR } from "./actionTypes";

function* fetchData({ parameter }) {
    try {
        const response = yield call(onLoad, parameter)
        yield put(getDataCollectorSuccessful(response))
    } catch (error) {
        yield put(collectorApiError(error))
    }
}

function* addItem({ payload }) {
    try {
        const response = yield call(onCreate, payload)
        yield put(collectorApiOk(response.message))
    } catch (error) {
        yield put(collectorApiError(error))
    }
}

function* updateItems({ payload }) {
    try {
        const response = yield call(onUpdate, payload)
        yield put(collectorApiOk(response.message))
    } catch (error) {
        yield put(collectorApiError(error))
    }
}

function* deleteItems({ payload }) {
    try {
        const response = yield call(onDelete, payload)
        yield put(collectorApiOk(response.message))
    } catch (error) {
        yield put(collectorApiError(error))
    }
}

function* collectorSaga() {
    yield takeEvery(GET_COLLECTOR, fetchData)
    yield takeEvery(ADD_NEW_COLLECTOR, addItem)
    yield takeEvery(UPDATE_COLLECTOR, updateItems)
    yield takeEvery(DELETE_COLLECTOR, deleteItems)
}

export default collectorSaga;